import React from "react";
import "./Programs.css";
import { programsData } from "../../data/programsData";
import RightArrow from "../../assets/rightArrow.png";
const Programs = () => {
  // console.log(programsData);
  return (
    <div className="Programs" id="programs">
      {/* header */}
      <div className="programs-header">
        <span className="stroke-text">Explore our</span>
        <span>Programs</span>
        <span className="stroke-text">to shape you</span>
      </div>
      <div className="program-categories">
        {programsData.map(({ image, details, heading }, index) => (
          <div key={index} className="category">
            {image}
            <span>{heading}</span>
            <span>{details}</span>
            <div className="join-now">
              <span>Join Now</span>
              <img src={RightArrow} alt="RightArrow" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Programs;
